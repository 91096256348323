.hero-container {
  position: relative;
  // height: 328px;
  display: flex;
  justify-content: center;
  margin-bottom: 44px;

  & img {
    width: 100%;
  }
}

.hero-title {
  position: absolute;
  top: 8px;
  margin-right: -20px;
  margin-bottom: -50px;
  &__text {
    font-size: 3.5rem;
    text-align: left;
    line-height: 3.4rem;
    color: #0f4968;
  }
}

@media (max-width: 1100px) {
  .hero-title {
    &__text {
      font-size: 2rem;
      line-height: 3.2rem;
    }
  }
}

@media (max-width: 700px) {
  .hero-title {
    &__text {
      font-size: 2rem;
      line-height: 2.3rem;
    }
  }
}
