@import url("https://fonts.googleapis.com/css2?family=Cormorant+Garamond:ital,wght@0,300;0,400;0,700;1,400;1,700&family=Source+Sans+Pro:wght@300;400;700&display=swap");
$blueText: #1974a5;

body {
  background-color: #bddae9;
  font-family: "Source Sans Pro", sans-serif;
}

h2,
h1 {
  font-weight: 400;
}

.brown-text {
  color: $blueText;
}

.light-background {
  background-color: #dfedee;
}

.page-contents {
  min-height: 100vh;
  animation: 1s fadeIn ease;
  max-width: 1260px;
  margin: auto;
}

.button {
  border: none;
  margin: 4px;
  padding: 7px;
  border-radius: 9px;
  background-color: $blueText;
  color: white;
  font-size: 1.2rem;
  cursor: pointer;

  &:hover {
    background-color: white;
    color: $blueText;
  }
}

.white-button {
  border: none;
  margin: 4px;
  padding: 7px;
  border-radius: 9px;
  color: $blueText;
  background-color: #eee;
  font-size: 1rem;
  cursor: pointer;

  &:hover {
    color: white;
    background-color: $blueText;
  }
}

.main-container {
  background-color: white;
  padding: 25px;
  border-radius: 20px;
}

.line {
  height: 6px;
  background-color: $blueText;
  margin-top: 8px;
  margin-bottom: -10px;
}

.body-text {
  font-size: 1.3rem;
  line-height: 1.9rem;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@media (max-width: 700px) {
  .body-text {
    font-size: 1rem;
    line-height: 1.5rem;
  }
  .main-container {
    padding: 16px;
  }
}
