@import url(https://fonts.googleapis.com/css2?family=Cormorant+Garamond:ital,wght@0,300;0,400;0,700;1,400;1,700&family=Source+Sans+Pro:wght@300;400;700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body{background-color:#bddae9;font-family:"Source Sans Pro", sans-serif}h2,h1{font-weight:400}.brown-text{color:#1974a5}.light-background{background-color:#dfedee}.page-contents{min-height:100vh;-webkit-animation:1s fadeIn ease;animation:1s fadeIn ease;max-width:1260px;margin:auto}.button{border:none;margin:4px;padding:7px;border-radius:9px;background-color:#1974a5;color:white;font-size:1.2rem;cursor:pointer}.button:hover{background-color:white;color:#1974a5}.white-button{border:none;margin:4px;padding:7px;border-radius:9px;color:#1974a5;background-color:#eee;font-size:1rem;cursor:pointer}.white-button:hover{color:white;background-color:#1974a5}.main-container{background-color:white;padding:25px;border-radius:20px}.line{height:6px;background-color:#1974a5;margin-top:8px;margin-bottom:-10px}.body-text{font-size:1.3rem;line-height:1.9rem}@-webkit-keyframes fadeIn{from{opacity:0}to{opacity:1}}@keyframes fadeIn{from{opacity:0}to{opacity:1}}@media (max-width: 700px){.body-text{font-size:1rem;line-height:1.5rem}.main-container{padding:16px}}

.appbar-container{position:fixed;top:0;left:0;right:0;color:white;width:100%;z-index:1000}.appbar-inner-container{height:55px;padding:0 16px;display:flex;align-items:center;justify-content:space-between;background-color:#1974a5;color:white}.appbar-title{font-size:1.6rem;font-weight:700;text-transform:capitalize;margin-left:35px}@media (max-width: 700px){.appbar-container{z-index:2000}}

.drawer-container{padding:24px;height:100%;color:white;background-color:#1974a5;display:flex;flex-direction:column}.nav-link{text-decoration:none;margin-bottom:2rem;font-size:1.2rem;color:white}.nav-link:hover{color:#c0f6ff}@media (max-width: 700px){.drawer-container h1{padding-top:30px}}

.two-columns{display:flex;flex-wrap:wrap;justify-content:center;margin-bottom:25px}.two-columns__column{width:42%;min-width:250px;margin:15px}.two-columns__column img{border-radius:20px}.two-columns__title{font-weight:700;font-size:23px}.home-video-container{border-radius:20px;padding:20px;background-color:white}.home-video-container iframe{height:300px}.class-news-details h3{padding-top:-8px;margin-top:-10px}@media (max-width: 900px){.two-columns__column{width:100%}}

.hero-container{position:relative;display:flex;justify-content:center;margin-bottom:44px}.hero-container img{width:100%}.hero-title{position:absolute;top:8px;margin-right:-20px;margin-bottom:-50px}.hero-title__text{font-size:3.5rem;text-align:left;line-height:3.4rem;color:#0f4968}@media (max-width: 1100px){.hero-title__text{font-size:2rem;line-height:3.2rem}}@media (max-width: 700px){.hero-title__text{font-size:2rem;line-height:2.3rem}}

.news-item{display:flex;justify-content:space-between}.news-item-video{height:400px}.news-item-text{flex:1 1;overflow:hidden;background-color:white;padding:21px;border-radius:20px}.news-item-text__date{font-size:32px;margin:10px 0}.news-item-text__title{font-weight:700;font-size:23px;margin:10px 0}.news-item-text__description{font-size:26px;font-weight:400;margin:10px 0}.news-item-text__details h3{padding-top:-8px;margin-top:-10px}.news-item-text__venue{font-size:26px}.news-item-text ul{list-style:none}.news-item-image-container{width:45%;border-radius:20px;overflow:hidden;max-height:480px}.news-item-image{height:110%;min-width:110%;object-fit:cover;object-position:center}iframe{width:100%;height:100%}

.footer-container{background-color:black;padding:10px;color:whitesmoke}.footer-container a{color:white;text-decoration:none}

.contact-container{padding:35px;display:flex;flex-direction:column;align-items:center;width:400px;color:#1974a5;text-align:center}.contact-container a{text-decoration:none;max-width:100%}.contact-data-container{display:flex;flex-direction:column;align-items:center}@media (max-width: 550px){.contact-container{width:210px;padding:20px;font-size:0.9rem}}

.portrait-contents{padding:35px;max-width:1000px;margin:auto}.portrait-contents__text{min-width:500px;flex:1 1}.portrait-contents__image{position:relative;margin-bottom:20px;height:450px;width:100%;overflow:hidden;background-color:white;border-radius:20px}.portrait-contents__image img{width:100%;object-fit:cover;object-position:0px -60px}@media (max-width: 1100px){.portrait-contents__image{height:200px}.portrait-contents__text{min-width:90%}}@media (min-width: 701px) and (max-width: 1099px){.portrait-contents__image{height:300px}}@media (max-width: 700px){.portrait-contents{padding:15px}}

.mediapage-container{padding:35px}iframe{border-radius:20px}.videos-container{padding:20px;display:flex;flex-wrap:wrap;justify-content:center}.video-card{height:350px;width:45%;min-width:200px;margin:25px}@media (min-width: 701px) and (max-width: 1550px){.videos-container{flex-direction:row;justify-content:center;width:100%}.video-card{height:230px;width:45%;min-width:200px;margin:15px}}@media (max-width: 700px){.mediapage-container{padding:10px}.contents-container{flex-direction:column}.videos-container{flex-direction:column;align-items:center;margin:auto}.video-card{width:100%;min-width:0;margin:10px}}

.recording-card-outer-container{margin:35px auto;background-color:white;border-radius:20px;min-width:900px;padding:30px}.recording-card-contents{display:flex}.recording-card-contents__image{width:50%}.recording-card-contents__image img{width:100%}.recording-card-details{padding-left:20px;width:100%}.recording-card-details__title{font-size:32px;font-weight:900}.recording-card-details__details{margin-top:-10px;font-size:23px}.audio-controls-container{display:flex;flex-wrap:wrap}.audio-control{margin:1%;width:47%;white-space:nowrap;overflow:hidden !important;text-overflow:ellipsis}.audio-control__text{font-size:1rem}.audio-control__controller{background-color:whitesmoke;margin-top:5px;text-align:center;font-size:0.8rem;border:1px solid black;border-radius:12px;width:98%}.buynow-buttons{display:flex;align-items:center}.buynow-buttons__label{font-size:0.8rem}.buynow-buttons__button{background-color:black;color:white;text-decoration:none;margin:0 5px;padding:5px 12px;font-size:0.8rem;font-weight:700;border-radius:5px}.buynow-buttons__button:hover{background-color:#5b5a5a}@media (max-width: 900px){.audio-control__text{font-size:0.8rem}}@media (max-width: 840px){.audio-controls-container{flex-direction:column}.audio-control{width:100%}.buynow-buttons{margin-top:10px}}@media (max-width: 700px){.recording-card-outer-container{min-width:80%;margin:10px;padding:20px}.recording-card-contents{flex-direction:column;align-items:center}.recording-card-contents__image{margin-bottom:16px}.recording-card-details{padding-left:0}}

.programme-page-contents{padding:35px}.category-tile{background-color:whitesmoke;padding:35px;border-radius:12px;margin:2%;width:20%;min-width:200px;cursor:pointer}.category-tile:hover{background-color:#d5eaf2}.category-tile__label{font-weight:600;font-size:1.2rem}.tiles-container{display:flex;flex-wrap:wrap;justify-content:center}.cat-header{position:sticky;top:60px;padding:10px 25px;z-index:700;background-color:#d5eaf2;max-width:1000px;margin:auto}.cat-header-light{position:sticky;top:60px;padding:10px 25px;z-index:700;background-color:white;border-radius:20px;max-width:1000px;margin:auto}.buttons-container{display:flex;flex-direction:column;width:20%;margin:auto;margin-bottom:25px}@media (max-width: 700px){.programme-page-contents{padding:15px}.buttons-container{width:100%}.category-tile{width:80%;text-align:center}}

.programme-card{margin:1rem auto;display:flex;max-width:1000px}.programme-card__image{width:40%;width:200px;height:200px;margin-right:1rem;overflow:hidden}.programme-card__image img{object-fit:cover;object-position:center;width:220px;min-height:220px}.programme-card-text{width:70%}.programme-card-text h1{font-size:1.5rem;margin-bottom:-19px}.programme-card-text h4{font-size:1.2rem;font-weight:400}.programme-card-text__title{font-weight:900;font-size:1.4rem}.programme-card-text__subtitle{font-weight:700}@media (max-width: 700px){.programme-card{flex-direction:column;align-items:center}.programme-card__image{width:60%}.programme-card-text{width:100%}}

.inner-container{padding:35px;width:80%;margin:auto}

.recording-cards-container{max-width:1000px;margin:auto}

